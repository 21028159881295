/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import PageTemplate from "@pageTemplates/SolutionBuilderPage";
import resolveImage from "@dataResolvers/resolveImage";
import QueryContainer, { HeadTemplate } from "../QueryContainer";

export const query = graphql`
  query SolutionBuilderQuery {
    craft {
      entry(section: "solutionBuilder") {
        ... on Craft_solutionBuilder_solutionBuilder_Entry {
          uid
          # builder
          backLink: link1 {
            text
            url
          }
          heading: heading0
          descriptor: descriptor0
          # cta
          helper: heading2
          cta: heading1
          button: link0 {
            text
            url
          }
          # meta
          title
          url
          metaTitle
          metaImage {
            ...ImageFragment
          }
          metaDescription
        }
      }
    }
  }
`;

const dataResolver = ({ entry }) => {
  const {
    backLink,
    heading,
    descriptor,
    tooltip,
    cta,
    button,
    // meta
    url,
    title,
    metaTitle,
    metaDescription,
    metaImage,
  } = entry;

  return {
    title,
    backLink,
    heading,
    descriptor,
    tooltip,
    cta,
    button,
    meta: {
      url,
      title,
      metaPageTitle: metaTitle,
      metaDescription,
      metaFeaturedImage: resolveImage(metaImage),
    },
  };
};

export const Head = ({ data, ...rest }) => {
  const { craft } = data;
  const resolvedData = dataResolver(craft);
  return <HeadTemplate {...rest} data={resolvedData} />;
};

const Page = props => (
  <QueryContainer
    template={PageTemplate}
    dataResolver={dataResolver}
    queryName="SolutionBuilderQuery"
    {...props}
  />
);

export default Page;
